// src/layouts/PageTestimonial/PageTestimonial.js

import React from "react"
import styled from 'styled-components'

import TestimonialBg from '../../images/testimonial-bg.png'
import StartQuote from '../../images/start-quote.png'
import EndQuote from '../../images/end-quote.png'

const PageTestimonial = ({ pbTestimonialQuote, pbTestimonialAuthor, pbAuthorInfo }) => {

  return (
    <BlockContent 
      itemScope
      itemType="http://schema.org/Review"
      data-sal="slide-up"
      data-sal-duration="1000"
      data-sal-delay="300"
      data-sal-easing="ease"
    >
      <Background>
        <img src={TestimonialBg} class="testimonial-bg" alt="Gray dotted background" height="650" width="1320" />
      </Background>
      <TestimonialMain>
        <img src={StartQuote} class="start-quote" alt="Start Quote" height="235" width="108" />
        <div 
        itemProp="reviewBody"
        dangerouslySetInnerHTML={{__html: pbTestimonialQuote}} 
        />
        <img src={EndQuote} class="end-quote" alt="End Quote" height="235" width="107" />
      </TestimonialMain>
      <TestimonialMeta>
        <h4 itemProp="author">{pbTestimonialAuthor}</h4>
        <p>{pbAuthorInfo}</p>
      </TestimonialMeta>
    </BlockContent>
  )
}

const BlockContent = styled.section`
  position: relative;
  max-width: 1360px;
  width: 100%;
  margin: 70px auto;
  padding: 0 20px;
  padding-top: 180px;
  @media(max-width:1000px) {
    max-width: 700px;
  }
  @media(max-width: 767px) {
    max-width: 500px;
    margin: 50px auto;
    padding-top: 80px;
    overflow: hidden;
  }
`

const Background= styled.div`
  position: absolute;
  top: 0;
  left: 20px;
  width: calc(100% - 40px);
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  @media(max-width: 767px) {
    left: 0;
    width: 100%;
    img {
      height: 200%;
    }
  }
`

const TestimonialMain = styled.div`
  max-width: 970px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 70px 80px;
  p {
    font-family: "Work Sans";
    font-size: 36px;
    line-height: 42px;
    font-weight: 700;
    color: #1f1e1e;
    margin-bottom: 0;
    text-align: center;
    position: relative;
    z-index: 10;
  }
  img.start-quote {
    position: absolute;
    top: -180px;
    left: -10px;
  }
  img.end-quote {
    position: absolute;
    bottom: -270px;
    right: 0px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 20px;
    left: 20px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 1;
  }
  &:after {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    width: 100%;
    height: 100%;
    border: 10px solid #db4140;
    z-index: 1;
  }
  @media(max-width:1000px) {
    max-width: 570px;
    p {
      font-size: 24px;
      line-height: 1.4;
    }
  }
  @media(max-width: 767px) {
    padding: 25px 50px;
    p {
      font-size: 16px;
    }
    img.start-quote {
      max-width: 50px;
      height: auto;
      top: -80px;
      left: calc(50% - 25px);
    }
    img.end-quote {
      max-width: 50px;
      height: auto;
      bottom: -115px;
      right: calc(50% - 25px);
    }
    &:before {
      top: 10px;
      left: 20px;
      width: calc(100% - 30px);
      height: calc(100% - 5px);
    }
    &:after {
      top: 0px;
      left: 10px;
      width: calc(100% - 25px);
      border: 5px solid #db4140;
    }
  }
`

const TestimonialMeta = styled.div`
  max-width: 970px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  padding-top: 100px;
  padding-bottom: 60px;
  h4 {
    font-family: "Work Sans";
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #1e1f1f;
    margin-top: 0;
    margin-bottom: 10px;
  }
  p {
    font-family: "Work Sans";
    font-size: 18px;
    line-height: 21px;
    font-weight: 500;
    color: #696969;
    margin-top: 0;
    margin-bottom: 0;
  }
  @media(max-width:1000px) {
    max-width: 570px;
  }
  @media(max-width: 767px) {
    padding-top: 80px;
    padding-bottom: 20px;
    text-align: center;
    h4 {
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
  }
`

export default PageTestimonial