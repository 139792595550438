import React from "react"
import { Link } from 'gatsby'
import styled from 'styled-components'

import SmallArrow from '../svgs/small-arrow.svg'

const ContactUs = () => {

    return(
        <ContactFlex>
            <h4>Ready to Talk?</h4>
            <p><Link to="/contact/">Contact Us <SmallArrow/></Link></p>
        </ContactFlex>
    );

}

const ContactFlex = styled.section`
    max-width: 1320px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    h4 {
        font-family: "Nobel",sans-serif;
        font-size: 100px;
        line-height: 100px;
        font-weight: 700;
        color: #2b2520;
        margin-bottom: 20px;
    }
    p {
        margin: 0;
        a {
          font-family: "Work Sans";
          font-size: 30px;
          line-height: 35px;
          font-weight: 700;
          color: #db4140;
          margin-top: 0;
          margin-bottom: 10px;
          text-decoration: none;
          display: block;
          width: 300px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
              width: 24px;
              margin-left: 10px;
              transition: all .3s cubic-bezier(.77,0,.175,1);
          }
          &:hover {
              svg {
                  transform: translateX(10px);
              }
          }
        }
      }
      @media(max-width:1000px) {
        h4 {
          font-size: 60px;
          line-height: 1;
        }
      }
      @media(max-width:767px) {
        h4 {
          font-size: 44px;
        }
      }
`

export default ContactUs