import React  from "react"
import styled from 'styled-components'

import Layout from "../components/layout-v2"
import SEO from "../components/seo"
import StandardPageBuilder from "../components/standard-page-builder"
import ContactUs from "../components/contact-us"


const Page = ({ data: { page } }) => {

  const layouts = page.PageBuilder.standardPageBuilder;

  return (
    <Layout>
      <SEO 
      title={page.SEOmeta.metaTitle} 
      description={page.SEOmeta.metaDescription}
      keywords={page.SEOmeta.metaKeywords}
      ogTitle={page.SEOmeta.ogTitle} 
      ogDescription={page.SEOmeta.ogDescription}
      ogImage={page.SEOmeta.ogImage.localFile.childImageSharp.fluid}
      twitterTitle={page.SEOmeta.twitterTitle} 
      twitterDescription={page.SEOmeta.twitterDescription}
      twitterImage={page.SEOmeta.twitterImage.localFile.childImageSharp.fluid}
      />
      <article style={{padding: '0 20px', overflow: 'hidden'}}>
          <TitleSection>
              <h1>{page.title}</h1>
              <p>{page.PageBuilderSubtitle.pbPageSubtitle}</p>
          </TitleSection>
          <div>
              {   
                  layouts.map((layout, index) => {
                      return <StandardPageBuilder key={index} layoutData={layout} />
                  })
              }
          </div>
          <ContactUs />
      </article>

    </Layout>
  )
}

const TitleSection = styled.header`
    max-width: 1320px;
    width: 100%;
    padding: 80px 70px;
    margin: 0 auto; 
    margin-top: 20px;
    margin-bottom: 60px;
    border: 10px solid #db4140;
    h1 {
        font-size: 100px;
        margin-top: 0;
        margin-bottom: 0;
    }
    p {
        font-family: "Work Sans";
        font-size: 30px;
        font-weight: 400;
        color: #777c7c;
        margin-bottom: 0;
    }
    @media(max-width:1000px) {
      max-width: 700px;
      padding: 45px;
      h1 {
        font-size: 60px;
      }
      p {
        font-size: 24px;
      }
  }
  @media(max-width: 767px) {
      max-width: 500px;
      padding: 20px;
      margin-bottom: 20px;
      border: 5px solid #db4140;
      h1 {
        font-size: 44px;
      }
      p {
        font-size: 18px;
        line-height: 1.4;
      }
  }
`

export default Page

export const pageQuery = graphql`
  query PageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current page by id
    page: wpPage(id: { eq: $id }) {
      id
      title
      SEOmeta {
        metaTitle
        metaDescription
        metaKeywords
        ogTitle
        ogDescription
        ogImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1320, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1320, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      PageBuilderSubtitle {
        pbPageSubtitle
      }
      PageBuilder {
        standardPageBuilder {
          ... on WpPage_Pagebuilder_StandardPageBuilder_PbOneColumnContent {
            fieldGroupName
            pbFullContent
          }
          ... on WpPage_Pagebuilder_StandardPageBuilder_PbTwoHalfColumns {
            fieldGroupName
            pbLeftColumnContent
            pbRightColumnContent
          }
          ... on WpPage_Pagebuilder_StandardPageBuilder_PbTwoThirdsOneThird {
            fieldGroupName
            pbLeftColumnTwoThirds
            pbRightColumnOneThird
          }
          ... on WpPage_Pagebuilder_StandardPageBuilder_PbTwoColumnRightImage {
            fieldGroupName
            pbImageLeftColumnContent
            pbRightColumnImage {
              title
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WpPage_Pagebuilder_StandardPageBuilder_PbTwoColumnLeftImage {
            fieldGroupName
            pbImageRightColumnContent
            pbLeftColumnImage {
              title
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WpPage_Pagebuilder_StandardPageBuilder_PbPageTestimonial {
            fieldGroupName
            pbAuthorInfo
            pbTestimonialAuthor
            pbTestimonialQuote
          }
          ... on WpPage_Pagebuilder_StandardPageBuilder_PbCallToAction {
            fieldGroupName
            pbCtaButtonLink
            pbCtaButtonText
            pbCtaTopContent
            pbCtaBottomImage {
              title
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1320, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`