// src/components/standard-page-builder.js

import React from "react"
import OneColumnContent from "../layouts/OneColumnContent"
import PbTwoColumnImageRight from "../layouts/PbTwoColumnImageRight"
import PbTwoColumnImageLeft from "../layouts/PbTwoColumnImageLeft"
import PageTestimonial from "../layouts/PageTestimonial"
import TwoHalfColumns from "../layouts/TwoHalfColumns"
import TwoThirdsOneThird from "../layouts/TwoThirdsOneThird"
import PageCallToAction from "../layouts/PageCallToAction"

const StandardPageBuilder = ({ layoutData }) => {

  const layoutType = layoutData.fieldGroupName

  /**
   * Default component
   */
  const Default = () => <div>In AllLayouts the mapping of this component is missing: {layoutType}</div>

  /**
   * Mapping the fieldGroupName(s) to our components
   */
  const layouts = {
    page_Pagebuilder_StandardPageBuilder_PbOneColumnContent: OneColumnContent,
    page_Pagebuilder_StandardPageBuilder_PbTwoColumnRightImage: PbTwoColumnImageRight,
    page_Pagebuilder_StandardPageBuilder_PbTwoColumnLeftImage: PbTwoColumnImageLeft,
    page_Pagebuilder_StandardPageBuilder_PbPageTestimonial: PageTestimonial,
    page_Pagebuilder_StandardPageBuilder_PbTwoHalfColumns: TwoHalfColumns,
    page_Pagebuilder_StandardPageBuilder_PbTwoThirdsOneThird: TwoThirdsOneThird,
    page_Pagebuilder_StandardPageBuilder_PbCallToAction: PageCallToAction,
    page_default: Default
  }

  /**
   * If layout type is not existing in our mapping, it shows our Default instead.
   */
  const ComponentTag = layouts[layoutType] ? layouts[layoutType] : layouts['page_default']

  return (
    <ComponentTag {...layoutData} />
  )
}

export default StandardPageBuilder