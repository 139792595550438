// src/layouts/PageCallToAction/PageCallToAction.js

import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import Img from "gatsby-image"

const PageCallToAction = ({ pbCtaButtonLink, pbCtaButtonText, pbCtaTopContent, pbCtaBottomImage }) => {

  return (
    <BlockContent>
        <div class="pb-top-content" 
        data-sal="slide-up"
        data-sal-duration="1000"
        data-sal-delay="300"
        data-sal-easing="ease"
        dangerouslySetInnerHTML={{__html: pbCtaTopContent}} />
        <div class="pb-cta-link"
        data-sal="slide-up"
        data-sal-duration="1000"
        data-sal-delay="300"
        data-sal-easing="ease"
        >
            <Link to={pbCtaButtonLink}>{pbCtaButtonText}</Link>
        </div>
        <div
        data-sal="slide-up"
        data-sal-duration="1000"
        data-sal-delay="300"
        data-sal-easing="ease"
        >
          <Img fluid={pbCtaBottomImage.localFile.childImageSharp.fluid} alt={pbCtaBottomImage.title} />
        </div>
    </BlockContent>
  )
}

const BlockContent = styled.section`
    max-width: 1160px;
    width: 100%;
    margin: 100px auto;
    padding: 0px;
    text-align: center;
    h2 {
        font-family: "Nobel",sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 65px;
        line-height: 75px;
        color: #2b2520;
        margin-top: 0;
        margin-bottom: 0;
    }
    h3 {
      font-family: "Nobel",sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 50px;
      line-height: 60px;
      color: #2b2520;
      margin-top: 0;
      margin-bottom: 0;
    }
    h4 {
      font-family: "Nobel",sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 40px;
      line-height: 48px;
      color: #2b2520;
      margin-top: 0;
      margin-bottom: 0;
    }
    p {
        font-family: "Work Sans";
        font-size: 18px;
        font-weight: 400;
        line-height: 38px;
        color: #777c7c;
        margin-bottom: 0;
    }
    .pb-cta-link {
        a {
            font-family: "Work Sans";
            font-size: 20px;
            line-height: 1;
            font-weight: 700;
            color: #fff;
            text-decoration: none;
            background-color: #db4140;
            display: inline-block;
            padding: 20px 50px;
            margin: 50px auto;
        }
    }
    @media(max-width:1000px) {
        max-width: 590px;
      h2 {
          font-size: 44px;
          line-height: 1.3;
          margin-bottom: 10px;
          padding-left: 0;
      }
      h3 {
          font-size: 36px;
          line-height: 1.3;
          margin-bottom: 10px;
      }
      h4 {
          font-size: 28px;
          line-height: 1.3;
          margin-bottom: 10px;
      }
      p {
        padding-left: 0;
      }
  }
  @media(max-width:767px) {
    max-width: 440px;
    margin: 30px auto;
      h2 {
          font-size: 36px;
      }
      h3 {
          font-size: 28px;
      }
      h4 {
          font-size: 20px;
      }
      p {
          font-size: 16px;
          line-height: 1.6;
      }
  }
`

export default PageCallToAction